import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import MediaLibrary from '../components/MediaLibrary';
import { parseQuery } from '../utils/parseQuery';

const StyledMediaPage = styled.div`
  padding-bottom: 20rem;
`;

const MediaPage = () => {
  const crumbs = [{ p: 'Hem', to: '/' }, { p: 'Filer' }];

  const context = () => {
    if (typeof window === 'undefined') return {};
    const query = parseQuery(window.location);
    return query;
  };

  return (
    <Layout>
      <StyledMediaPage>
        <h1 className="page-header">Filer</h1>
        <Breadcrumbs crumbs={crumbs} />
        <MediaLibrary context={context()} />
      </StyledMediaPage>
    </Layout>
  );
};

export default MediaPage;
